import * as React from "react";
import Layout from "../components/layout";
import { useStaticQuery, graphql } from "gatsby";
import { Seo } from "../components/seo";
import ExtendedTitle from "../components/extended-title";
import Heading from "../components/heading";
import SubHeading from "../components/subheading";
import { CheckCircleIcon } from "@heroicons/react/24/outline";
import {
	MegaphoneIcon,
	SparklesIcon,
	CubeTransparentIcon,
} from "@heroicons/react/24/solid";
import Button from "../components/button";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Cta from "../components/cta";
import Testimonials from "../components/testimonials";

const roundup = [
	"Altijd inclusief nabewerking",
	"Eerste beelden binnen een paar uur mogelijk, en altijd binnen een week.",
	"Aangeleverd in de hoogste resolutie, en alle denkbare formaten daaronder. Liever een ander formaat of met watermerk? Regelen we!",
	"Zonder gekke afspraken en licenties met betrekking tot het gebruik. Gebruik het beeld zoals jij wil.",
	"Een heldere prijs vooraf, en geen verrassingen achteraf.",
];

const types = [
	{
		icon: <SparklesIcon className="w-12 h-12 block my-4" />,
		title: "Registratie",
		content:
			"Van festivals tot club evenementen. Van sport tot congressen. Met ruim 1.000+ evenementen op de teller durven we wel te zeggen dat we genoeg ervaring hebben met het registeren van evenementen. Van weekender tot buurtfeest. Van zakelijk congres tot klein diner. We worden enthousiast van teamwork en impact maken, en helpen je volgend jaar uitverkopen.",
	},
	{
		icon: <CubeTransparentIcon className="w-12 h-12 block my-4" />,
		title: "Beeldbank",
		content:
			"Wanneer je regelmatig communiceert, doe je dat graag met genoeg eye candy. Wij helpen je graag met een uitgebreide beeldbank vol authentieke beelden. Jouw visueel kapitaal om de wereld te veroveren. Geen stockfoto's meer, maar een constante en consistente stroom beelden.",
	},
	{
		icon: <MegaphoneIcon className="w-12 h-12 block my-4" />,
		title: "Campagne",
		content:
			"Ook wanneer alles samen moet komen in enkele beelden helpen we je graag op weg. We kijken met jou mee naar de stijl, strategie en concept. Nemen het regelwerk uit handen. En zorgen dat alle puzzelstukjes op hun plek vallen.",
	},
];

const PhotographyPage = () => {
	const data = useStaticQuery(graphql`
		query PhotographyQuery {
			directus {
				hero_images(filter: { location: { _eq: "photography" } }) {
					image {
						id
						imageFile {
							childImageSharp {
								gatsbyImageData(width: 1200, quality: 75)
							}
						}
					}
				}
				cases {
					images(limit: 5) {
						directus_files_id {
							id
							imageFile {
								childImageSharp {
									gatsbyImageData(width: 400, quality: 75)
								}
							}
						}
					}
				}
			}
		}
	`);
	return (
		<>
			<Layout>
				<main className="divide-y divide-gray">
					<ExtendedTitle
						backButtonTo="/portfolio"
						backButtonTitle="Bekijk portfolio"
						subtitle="We push the pause button of life"
						image={data.directus.hero_images[0].image}
					>
						Fotografie
					</ExtendedTitle>
					<section className="bg-off-white py-48">
						<div className="max-w-6xl mx-auto text-lg px-8">
							<p className="max-w-4xl mx-auto text-xl md:text-2xl leading-normal mb-16 md:text-center font-bold">
								Ken je dat gevoel, wanneer muziek, sport of
								entertainment je in extase brengt en je
								eigenlijk wel op de pauzeknop zou willen drukken
								om alle emoties diep op je in te laten werken en
								zo lang mogelijk van het moment wil blijven
								genieten?
							</p>
							<div className="md:columns-2 text-base gap-16">
								<p className="mb-4">
									Wij hebben die knop, en we zorgen ervoor dat
									je dagen - of zelfs jaren - na dat moment de
									kippenvel nog terug op je armen krijgt. Wij
									voorzien onze opdrachtgevers 365 dagen per
									jaar van foto's, advies en management tools
									om het beste van zichzelf - of anderen - te
									laten zien. We denken vanuit het Hero, Hub,
									Hygiene-model. Hierdoor heb je altijd genoeg
									content voor jouw merk of evenement voor een
									perfecte on- en offline presentatie (en
									schieten we vaak net wat meer dan je ons
									gebrieft hebt).
								</p>

								<p>
									Consistentie en herhaling leveren de beste
									resultaten. Daarom zetten wij altijd in op
									een duurzame samenwerking. Zodat wij - als
									op elkaar ingespeelde partners - het beste
									in elkaar naar boven halen.
								</p>
							</div>
							<Heading as="h3" className="mt-8 mb-4">
								Waar ga jij voor vandaag?
							</Heading>
							<div className="md:columns-2 text-base gap-16">
								<p className="mb-4">
									Geen uitvoering zonder plan of doel. Daarom
									denken we bij Spark graag mee over jouw
									contentstrategie. Welk medium zetten we waar
									in? Dat maakt het verschil tussen een kiekje
									en beeld dat daadwerkelijk gebruikt wordt.
								</p>
								<p>
									Bij Spark zijn we elke dag bezig met
									fotografie. Dat maakt dat we oneindig veel
									kennis hebben van onze omgeving. We kennen
									de mensen, media, mogelijkheden en de
									kosten. Daardoor kunnen we vooraf in een
									vroeg stadium een goede inschatting maken
									van de mogelijkheden en resultaten. We weten
									waar de doelgroep zit en hoe we die met
									beeld moeten bereiken. Samen met jouw doelen
									kijken we welke innovaties en expertises
									passen binnen het budget en je gestelde
									deadline.
								</p>
							</div>

							<div className="text-center">
								<Button
									to="/portfolio"
									dark={true}
									className="mt-16 mx-auto"
								>
									Bekijk ons portfolio
								</Button>
							</div>
						</div>
					</section>
					<section className="bg-gray text-white text-center h-[50vh] md:h-[70vh] md:min-h-[700px] overflow-hidden relative">
						<div className="grid grid-cols-3 md:grid-cols-4 lg:grid-cols-6 xl:grid-cols-8 gap-2 md:gap-4 -rotate-12 absolute origin-top-left -left-1/4">
							{data.directus.cases.map((item, i) =>
								item.images.map((image, i) => (
									<div key={image.directus_files_id.id}>
										<GatsbyImage
											imgClassName="rounded-lg"
											className="mb-4 object-cover h-full w-auto rounded-lg"
											alt="Spark"
											image={getImage(
												image.directus_files_id
													.imageFile
											)}
										/>
									</div>
								))
							)}
						</div>
					</section>
					<section className="bg-off-white py-48">
						<div className="container mx-auto">
							<Heading as="h2" className="mb-2">
								Fotografie voor alle doeleinden
							</Heading>
							<SubHeading className="mb-16">
								Meerdere beelden, of alles-in-één. Regisseren of
								geregistreerd.
							</SubHeading>

							<div className="grid md:grid-cols-3 gap-16">
								{types.map((item, i) => (
									<div key={i}>
										{item.icon}
										<Heading as="h3" className="mb-4">
											{item.title}
										</Heading>
										<p>{item.content}</p>
									</div>
								))}
							</div>

							<div className="text-center">
								<Button
									to="/contact"
									dark={true}
									className="mt-16 mx-auto"
								>
									Neem contact op
								</Button>
							</div>
						</div>
					</section>
					<Testimonials />
					<section className="bg-off-white py-48">
						<div className="max-w-2xl mx-auto px-8">
							<Heading as="h2" className="mb-2">
								Dit beloven we jou
							</Heading>
							<p className="text-xl">
								Altijd inclusief de service van Spark
							</p>
							<ul className="mt-8 space-y-3">
								{roundup.map((item, i) => (
									<li className="flex" key={i}>
										<CheckCircleIcon className="h-7 w-7 flex-none text-lime-500" />
										<span className="ml-4 text-base">
											{item}
										</span>
									</li>
								))}
							</ul>
							<div className="text-center mt-16">
								<Button to="/contact" dark={true}>
									Neem contact op
								</Button>
							</div>
						</div>
					</section>
				</main>
				<Cta />
			</Layout>
		</>
	);
};

export default PhotographyPage;

export const Head = () => <Seo title="Fotografie - SPARK" />;
